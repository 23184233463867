<template>
    <div class="wx-activity-main">
        <div class="activity-head">
            <p class="head-title">
                {{promotedTitle}}
            </p>
            <div class="head-invitation">
                <div class="invitation-title">
                    <i class="title-left"></i>
                    <span class="title">获奖进度</span>
                    <i class="title-right"></i>
                </div>
                <div class="award-progress">
                    <div class="progress-item" v-for="i in ruleList.length" :class="{'itemActive':status>=i}" :key="i">
                        <div class="content">
                            <p class="Grade-txt">V{{i}}</p>
                            <i class="icon"></i>
                            <p class="people-num">{{ruleList[Number(i)-1].wxSubCount}}人</p>
                        </div>
                    </div>
                    <i class="actives" v-if="status>0" :style="'width:'+((100/ruleList.length*0.5)+(status==ruleList.length?(100/ruleList.length*0.5):0)+(status-1)*100/ruleList.length)+'%'"></i>
                </div>
                <div class="invitation-title invitation-people" >
                    <i class="title-left"></i>
                    <span class="title">邀请好友{{peopleList.length}}人</span>
                    <i class="title-right"></i>
                </div>
                <div class="people-box">
                    <div class="people-list">
                        <div class="people-item" v-for="i in (peopleList.length <= 6?6:peopleList.length)" :key="i">
                            <img :src="peopleList[Number(i)-1].faceUrl" v-if="(peopleList.length <= 6?i<=peopleList.length:true)">
                            <img v-else src="@/assets/img/incitated-icon.png" @click="goApp">
                        </div>
                        <div class="people-item" v-if="peopleList.length>=6">
                            <img  src="@/assets/img/incitated-icon.png" @click="goApp">
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-back">
                <div class="bottom-btn" @click="goApp"></div>
            </div>
            <div class="rule" @click="ruleShow = true">
                规则
            </div>
        </div>
        <div class="acticity-shareSteps">
            <div class="Steps-item">
                <img src="@/assets/img/share-wx-icon.png" >
                <p class="txt">微信分享给 好友</p>
            </div>
            <i class="icon-arrow"></i>
            <div class="Steps-item">
                <img src="@/assets/img/share-user-icon.png" >
                <p class="txt">满足礼品相 对应条件</p>
            </div>
            <i class="icon-arrow"></i>
            <div class="Steps-item">
                 <img src="@/assets/img/share-im-icon.png" >
                <p class="txt">联系客服领 取礼品</p>
            </div>
        </div>
        <div class="gift-details">
            <div class="gift-title">
                礼品详情
            </div>
            <div class="gift-list">
                <div class="gift-item" v-for="i in (ruleList.length>3&&!moreType?3:ruleList.length)" :key="i">
                    <img src="@/assets/img/gift-img.png" >
                    <div class="gift-infor">
                        <p class="title">
                            <span class="lable">V{{ruleList[Number(i)-1].level}}礼品：</span>
                            <span class="name">{{ruleList[Number(i)-1].reward}}</span>
                        </p>
                        <p class="meet-condition">满足条件：邀请{{ruleList[Number(i)-1].wxSubCount}}人关注</p>
                    </div>
                    <div class="gift-btn" :class="{'btn-primars':ruleList[Number(i)-1].status==0}" @click="gobuy(i)">
                        {{ruleList[Number(i)-1].status==0?'去完成':'已完成'}}
                    </div>
                </div>
                <div class="more-box" @click="moreType=!moreType" v-if="ruleList.length>3">
                    <span class="txt">{{!moreType?'查看更多':'收起'}}</span>
                    <van-icon :name="!moreType?'arrow-down':'arrow-up'" />
                </div>
            </div>
        </div>
        <div class="mygift">
            <div class="mygift-title">
                我的礼品
            </div>
            <div class="mygift-list">
                <div class="list-item" v-for="(item,index) in myGift" :key="index">
                    <img src="@/assets/img/gift-img.png" >
                    <div class="mygift-infor">
                        <div class="infor-gift-name">
                            <span class="lable">V{{item.level}}礼品: </span>
                            <p class="txt">{{item.reward}}</p>
                        </div>
                        <p class="goIms" v-if="item.status == 1">兑换方式：联系客服 <i class="icon"></i></p>
                        <p class="gift-status" v-if="item.status == 2">
                            兑换状态：已领取
                        </p>
                        <p class="gift-status" v-if="item.status == 0">兑换状态：未达标</p>
                    </div>
                    <div class="icon-status" v-if="item.status!=0">
                        <img src="@/assets/img/unclaimed-icon.png" v-if="item.status == 1" alt="">
                        <img src="@/assets/img/Received-icon.png" v-else>
                    </div>
                    <i class="border"></i>
                </div>
                <div class="mygift-null" v-if="myGift.length == 0">
                    <img src="@/assets/img/gift-null-icon.png" >
                    <p class="txt">还未获得礼品，再努力一下吧～</p>
                </div>
            </div>
        </div>
        <van-overlay :show="ruleShow" class-name="rule" @click="ruleShow = false">
            <div class="promotion-rule" @click.stop>
                <div class="promot-content">
                    <div class="content-title">
                        <i class="title-left"></i>
                        <span class="title">活动规则</span>
                        <i class="title-right"></i>
                    </div>
                    <div class="rule-txt" v-html="gameRule" >
                    </div>
                </div>
                <i class="close-after" @click="ruleShow = false"></i>
            </div>
        </van-overlay>
        <!-- <van-overlay :show="shareShow" class-name="shareover" @click="shareShow = false">
            <div class="share-box" >
                <img src="@/assets/img/share-arrow-icon.png" alt="">
                <div class="share-txt">
                    <p>分享给好友</p>
                    <p>大奖轻松拿到手</p>
                </div>
            </div>
        </van-overlay> -->
    </div>
</template>

<script>
import { promotionGet } from "@/api/promotion";
import wxConfig from '@/utils/wxconfig';

export default {
    data(){
        return{
            promotedTitle:"",
            promotId:'',
            shareShow:false,
            ruleShow:false,
            moreType:false,
            gameRule:"",
            ruleList:[],
            status:0,
            myGift:[],
            peopleList:[],
            activityStatus:''
        }
    },
    created(){
        this.promotId = this.$route.query.promotionId;
        this.getPromotedData();
    },
    mounted(){
        // this.goShare();
        
    },
    methods:{
        isIos(){
            let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
            let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isAndroid) {
                // window.android.JSToNative(JSON.stringify(params))
                return false;
            } else {
                return true;
                // window.webkit.messageHandlers.JSToNative.postMessage(params)
                // JSToNative(JSON.stringify(params))
            }
        },
        async getPromotedData(){
            const toast = this.$Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "加载中..."
            });
            let datadd = {
                id:this.promotId,
                userId:this.$route.query.userId
            }
            let result = await promotionGet(datadd);
            toast.clear();
            if(result.data.errorCode == 0){
                this.gameRule = result.data.data.gameRule.split("\n").join("<br/>");
                this.ruleList = result.data.data.promotionLevels;
                this.peopleList = result.data.data.promotionUsers;
                this.promotedTitle = result.data.data.name;
                document.title = this.promotedTitle;
                this.activityStatus = result.data.data.status;
                this.myGift = result.data.data.promotionRewards;
                this.status = 0;
                result.data.data.promotionLevels.map(item=>{
                    if(item.status != 0 ){
                        this.status++;
                    }
                })
                // this.peopleList = 
            }
        },
        // 分享
        async goShare(){
            this.$axios1.post("/share/promotion", {promotionId:this.promotId}).then(async res => {
                await this.setWxConfig(res.data);
                // this.goApp;
            });
        },
        gobuy(i){
            if(this.activityStatus == 2){
                this.$toast("活动已结束")
                return;
            }
            if(this.ruleList[Number(i)-1].status==0){
                this.goApp();
            }
        },
        goApp(){
            if(this.isIos()){
                // window.webkit.messageHandlers.JSToNative.postMessage(params)
            }else{
                window.android.promotionShareClick();
            }
        },
        async setWxConfig(data) {
            let self = this;
            await wxConfig.share({
                title: data.title,
                desc: data.desc,
                imgUrl: data.imgUrl,
                link: data.toUrl,
                circleFriend: function() {
                    self.changeShareState(data.pageTraceId, true);
                },
                failFriend: function() {
                    self.changeShareState(data.pageTraceId, false);
                }
            });
        },
    }
}
</script>

<style lang="scss">
.rule{
    display: flex;
    justify-content: center;
    align-items: center;
    .promotion-rule{
        width: 11.4rem;
        // min-height: 9rem;
        height: 13.4rem;
        background: #FFE7BF;
        border-radius: 0.6rem;
        padding:  0.4rem 0.34rem;
        box-sizing: border-box;
        display: flex;
        position: relative;
        margin-top: -2rem;
        &::after{
            content: " ";
            position: absolute;
            width:  2.68rem;
            height: 3.84rem;
            background: url("~@/assets/img/rule-after-back.png") no-repeat;
            background-size: 100% 100%;
            z-index: 2;
            right: -0.75rem;
            bottom: -0.6rem;
        }
        &::before{
            content: " ";
            position: absolute;
            width:  3.5rem;
            top: -0.35rem;
            left: -1rem;
            height: 1.26rem;
            background: url("~@/assets/img/rule-befor-icon.png") no-repeat;
            background-size: 100% 100%;
            z-index: 2;
        }
        .close-after{
            width: 0.84rem;
            height: 0.84rem;
            display: block;
            position: absolute;
            bottom: -2.5rem;
            left: 50%;
            transform: translate(-50% , 0);
            background: url("~@/assets/img/tj_close_icon.png") no-repeat;
            background-size: 100% 100%;
        }
        .promot-content{
            flex: 1;
            // height: 100%;
            border-radius: 0.6rem;
            box-sizing: border-box;
            padding:0.4rem 0.8rem;        
            width: 100%;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: column;
            .content-title{
                flex: auto 0 0;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0.2rem  0;
                padding-bottom: 0.6rem;
                border-bottom:  0.04rem dashed #DDB18B;
                .title{
                    margin: 0 0.6rem;
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #D88135;
                }
                .title-left,.title-right{
                    width: 1.64rem;
                    height: 0.2rem;
                    background: url("~@/assets/img/activity-head-title-icon-left.png") no-repeat;
                    background-size: 100% 100%;
                }
                .title-right{
                    background: url("~@/assets/img/activity-head-title-icon-right.png") no-repeat;
                    background-size: 100% 100%;
                }
            }
            .rule-txt{
                margin: 0.4rem 0;
                font-size: 0.56rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 0.72rem;
                flex: 1;
                overflow: auto;
            }
        }
    }
}
.shareover{
    
    .share-box{
        float: right;
        width: max-content;
        margin: 2.5rem auto 0 auto;
        margin-right: 1.2rem;
        display: flex;
        align-items: flex-end;
        flex-direction: column;
        img{
            width: 2.9rem;
            height: 3.5rem;
        }
        .share-txt{
            float: right;
            height: 2.2rem;
            width: 8.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: #F65947;
            border-radius: 1.1rem;
            margin-right: 1.25rem;
            position: relative;
            &::after{
                position: absolute;
                content: " ";
                width: 3.5rem;
                height: 2rem;
                background: url("~@/assets/img/share-progress-icob.png") no-repeat;
                background-size: 100% 100%;
                top: -88%;
                left: -3%;
            }
            &::before{
                position: absolute;
                content: " ";
                width: 0.96rem;
                height: 1.26rem;
                background: url("~@/assets/img/share-right-icon.png") no-repeat;
                background-size: 100% 100%;
                top: 20%;
                right: -15%;
            }
            p{
                font-size: 0.64rem;
                font-family: PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 0.88rem;
            }
        }
    }
}
.wx-activity-main{
    // 17.6
    overflow: auto;
    height: auto;
    min-height: 100%;
    background: #A33220;
    .activity-head{
        height: 16.6rem;
        // overflow: auto;
        display: grid;
        background: url("~@/assets/img/activity-head-back.png") no-repeat;
        background-size: 100% 100%;
        position: relative;
        border-radius: 0 0 0.8rem 0.8rem;
        .rule{
            position: absolute;
            font-size: 0.48rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FCE3BD;
            line-height: 0.72rem;
            padding: 0.2rem 0.5rem;
            background: rgba(115, 15, 0, 0.7);
            border-radius: 0.48rem 0px 0px 0.48rem;
            right: 0;
            top: 2.5rem;
        }
        .bottom-back{
            width: 100%;
            height: 2.8rem;
            position: absolute;
            left: 0;
            bottom: 0;
            transform: translate(0,24%);
            background: url("~@/assets/img/activity-head-bottom-back.png") no-repeat;
            background-size: 100% 100%;
            .bottom-btn{
                width: 10rem;
                height: 2.6rem;
                margin: 1rem auto auto;
                background: url("~@/assets/img/activity-bottom-btn.png") no-repeat;
                background-size: 100% 100%;
                transition:all 3s;
                animation: animationWidth 2000ms infinite;

            }
            // background-color: #A33220;
        }
        .head-title{
            font-size: 1.44rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FBE7E6;
            margin:1rem auto 0 auto;
            width: max-content;
            background: linear-gradient(0deg, #FFEFA6 0%, #FFFFFF 99.5849609375%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        .head-invitation{
            width:14rem;
            height: 13.7rem;
            background: url("~@/assets/img/invitation.png") no-repeat;
            background-size: 100% 14.2rem;
            margin: 0 auto;
            padding: 3.5rem 1rem 0 1rem;
            border-radius: 0 0 0.8rem 0.8rem;
            box-sizing: border-box;
            .invitation-title{
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0.2rem;
                .title{
                    margin: 0 0.4rem;
                    font-size: 0.64rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #D88135;
                }
                .title-left,.title-right{
                    width: 1.64rem;
                    height: 0.2rem;
                    background: url("~@/assets/img/activity-head-title-icon-left.png") no-repeat;
                    background-size: 100% 100%;
                }
                .title-right{
                    background: url("~@/assets/img/activity-head-title-icon-right.png") no-repeat;
                    background-size: 100% 100%;
                }
            }
            .invitation-people{
                margin: 0.9rem 0 0.3rem 0;
            }
            .award-progress{
                display: flex;
                justify-content: space-around;
                position: relative;
                margin: 0.6rem 0;
                &::after{
                    content: " ";
                    width: 100%;
                    height: 0.4rem;
                    background: #F8DBB3;
                    position: absolute;
                    border-radius: 0.2rem;
                    top: 50%;
                    transform: translate(0,-110%);
                    z-index: 1;
                }
                .actives{
                    height: 0.4rem;
                    border-radius: 0.2rem;
                    background:url("~@/assets/img/progress-back-icon.png"), linear-gradient(90deg, #F1630D, #EF4D3D);
                    // background-image: url("~@/assets/img/progress-back-icon.png");
                    background-size: 0.4rem 0.4rem;
                    position: absolute;
                    border-radius: 0.2rem;
                    top: 50%;
                    left: 0;
                    transform: translate(0, -110%);
                    z-index: 2;
                }
                // award-progress
                .progress-item{
                    flex: 1;
                    position: relative;
                    z-index: 3;
                    .content{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .icon{
                            height: 1.48rem;
                            width: 1.48rem;
                            margin-top: -0.25rem;
                            background: url("~@/assets/img/progress-icon.png") no-repeat center;  
                            background-size: 110% 110%;                           
                            position: relative;
                            z-index: 1;
                        }
                        .Grade-txt{
                            position: relative;
                            z-index: 2;
                            font-size: 0.4rem;
                            font-family: PingFang SC;
                            font-weight: 600;
                            color: #9B9190;
                            line-height: 0.48rem;
                            // -webkit-text-stroke: 0.04rem #F4F4F4;
                            // text-stroke: 1px #F4F4F4;
                            text-shadow:rgba(244, 244, 244, 1) 2px 0 0,rgba(244, 244, 244, 1) 0 2px 0,rgba(244, 244, 244, 1) -2px 0 0,rgba(244, 244, 244, 1) 0 -2px 0;
                            -webkit-text-shadow:rgba(244, 244, 244, 1) 2px 0 0,rgba(244, 244, 244, 1) 0 2px 0,rgba(244, 244, 244, 1) -2px 0 0,rgba(244, 244, 244, 1) 0 -2px 0;
                            -moz-text-shadow:rgba(244, 244, 244, 1) 2px 0 0,rgba(244, 244, 244, 1) 0 2px 0,rgba(244, 244, 244, 1) -2px 0 0,rgba(244, 244, 244, 1) 0 -2px 0;
                            *filter: Glow(color=#000, strength=1);
                        }
                        .people-num{
                            font-size: 0.4rem;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #EBC48E;
                            line-height: 0.48rem;
                            margin-top: 0.1rem;
                        }
                    }
                }
                .itemActive{
                    .content{
                        .Grade-txt{
                            font-size: 0.4rem;
                            font-family: PingFang SC;
                            font-weight: 600;
                            color: #EF4D3D;
                            line-height: 0.48rem;
                            // -webkit-text-stroke: 2px #FFF0DD;
                            // text-stroke: 1px #FFF0DD;
                            text-shadow:rgba(255, 241, 221, 1) 2px 0 0,rgba(255, 241, 221, 1) 0 2px 0,rgba(255, 241, 221, 1) -2px 0 0,rgba(255, 241, 221, 1) 0 -2px 0;
                            -webkit-text-shadow:rgba(255, 241, 221, 1) 2px 0 0,rgba(255, 241, 221, 1) 0 2px 0,rgba(255, 241, 221, 1) -2px 0 0,rgba(255, 241, 221, 1) 0 -2px 0;
                            -moz-text-shadow:rgba(255, 241, 221, 1) 2px 0 0,rgba(255, 241, 221, 1) 0 2px 0,rgba(255, 241, 221, 1) -2px 0 0,rgba(255, 241, 221, 1) 0 -2px 0;
                            *filter: Glow(color=#000, strength=1);
                        }
                        .icon{
                            background: url("~@/assets/img/progress-icon-active.png") no-repeat center;  
                            background-size: 110% 110%;
                        }
                    }
                }
            }
            .people-box{
                height: 1.8rem;
                width: 102%;
                overflow: auto;
                padding-left: 0.3rem;
                box-sizing: border-box;
                // display: box;
                margin-top: 0.6rem;
                -webkit-overflow-scrolling: touch;
                &::-webkit-scrollbar{
                    display: none;
                }
                .people-list{
                    width: max-content;
                }
                .people-item{
                    float: left;
                    width: 1.74rem;
                    height: 1.74rem;
                    margin-right: 0.6rem;
                    border-radius: 0.56rem;
                    overflow: hidden;
                    &:last-child{
                        margin-right: 0;
                    }
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
    .acticity-shareSteps{
        margin: 1rem 1rem;
        padding:1.2rem 0 0.6rem 0;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;
        align-items: center;
        .Steps-item{
            max-width: 2.5rem;
            img{
                width: 1.74rem;
                height: 1.74rem;
                display: block;
                margin: 0 auto;
            }
            .txt{
                font-size: 0.4rem;
                font-family: PingFang SC;
                font-weight: 300;
                color: #FFF6D6;
                line-height: 0.6rem;
                margin-top: 0.3rem;
                text-align: center;
            }
        }
        .icon-arrow{
            width: 1.48rem;
            height: 0.3rem;
            margin-top: -1.5rem;
            background: url("~@/assets/img/setps-arrow.png") no-repeat;
            background-size: 100% 100%;
        }
    }
    .gift-details{
        padding: 1.54rem 0.36rem 0.6rem 0.36rem;
        background: linear-gradient(0deg, #FED89A 0%, #FFE7C0 100%);
        border-radius: 0.4rem;
        margin:0 0.6rem;
        position: relative;
        .gift-title{
            position: absolute;
            width: 7.4rem;
            height: 1.6rem;
            background: url("~@/assets/img/gift-title-back.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.68rem;
            font-family: PingFang SC;
            font-weight: 600;
            color: #FFF0B6;
            top: 0%;
            left: 0%;
            transform: translate(44%, -0.8rem);
        }
        .gift-list{
            .gift-item{
                background: #FFF8E4;
                box-shadow: -1px -1px 0px 0px #FFF8E5;
                border-radius: 0.32rem;
                display: flex;
                align-items: center;
                padding: 0.52rem 0.36rem;
                margin-bottom: 0.4rem;
                img{
                    width: 1.3rem;
                    height: 1.3rem;
                    flex: auto 0 0;
                    margin-right: 0.3rem;

                }
                .gift-infor{
                    flex: 1;
                    overflow: auto;
                    .title{
                        display: flex;
                        width: 100%;
                        font-size: 0.6rem;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: #9A2100;
                        line-height: 0.6rem;
                        
                        .lable{
                            flex: auto 0 0;
                            width: max-content;
                        }
                        .name{
                            flex: 1;
                        }
                    }
                    .meet-condition{
                        font-size: 0.52rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #D09062;
                        margin-top: 0.28rem;
                    }
                    p{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .gift-btn{
                    flex: auto 0 0;
                    width: max-content;
                    margin-left: 0.4rem;
                    width: 2.52rem;
                    height: 1.12rem;
                    font-size: 0.52rem;
                    font-family: PingFang SC;
                    font-weight: 600;
                    color: #968C86;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #D0C7C1;
                    border-radius: 0.56rem;
                }
                .btn-primars{
                    background: linear-gradient(0deg, #EB3A17 0%, #FE963A 100%);
                    color: #FFFDD7;
                }
            }
            .more-box{
                font-size: 0.52rem;
                font-family: PingFang SC;
                font-weight: 400;
                color: #D09062;
                width: max-content;
                margin: 0.6rem auto 0 auto;
                .txt{
                    margin-right: 0.2rem;
                }
            }
        }
    }
    .mygift{
        background: url("~@/assets/img/mygift-back.png") no-repeat;
        background-size: 100% 6rem;
        margin:2rem 0.6rem 1.5rem 0.6rem;
        position: relative;
        padding: 2.2rem 0 1rem 0;
        .mygift-title{
            position: absolute;
            width: 7.4rem;
            height: 1.6rem;
            background: url("~@/assets/img/gift-title-back.png") no-repeat;
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0.68rem;
            font-family: PingFang SC;
            font-weight: 600;
            color: #FFF0B6;
            top: 0%;
            left: 0%;
            transform: translate(44%, -1.2rem);
        }
        .mygift-list{
            margin: 0 0.5rem 0 0.45rem;
            border-radius: 0.2rem;
            background-color: #FFFFFF;
            .list-item{
                padding: 0.6rem 0.8rem;
                display: flex;
                position: relative;
                &::after{
                    content: " ";
                    position: absolute;
                    width: 0.4rem;
                    height: 0.4rem;
                    border-radius: 50%;
                    left: -0.2rem;
                    bottom: -0.2rem;
                    background: #A33220;
                }
                &::before{
                    content: " ";
                    position: absolute;
                    width: 0.4rem;
                    height: 0.4rem;
                    border-radius: 50%;
                    right: -0.2rem;
                    bottom: -0.2rem;
                    background: #A33220;
                }
                &:last-child::after,&:last-child::before{
                    display: none;
                }
                .border{
                    position: absolute;
                    width: 88%;
                    border-bottom: 0.04rem dashed #F6BA99;
                    bottom: 0;
                }
                img{
                    flex: auto 0 0;
                    width: 0.84rem;
                    height: 0.84rem;
                    border-radius: 50%;
                    margin-right: 0.4rem;
                }
                .mygift-infor{
                    flex: 1;
                    overflow: auto;
                    .infor-gift-name{
                        display: flex;
                        font-size: 0.6rem;
                        font-family: PingFang SC;
                        font-weight: 600;
                        color: #9A2100;
                        margin-bottom: 0.3rem;
                        .lable{
                            flex: auto 0 0;
                        }
                        .txt{
                            flex: 1;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                    .goIms{
                        font-size: 0.52rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #9A2100;
                        .icon{
                            width: 0.88rem;
                            height: 0.54rem;
                            margin-left: 0.3rem;
                        }
                    }
                    .gift-status{
                        font-size: 0.52rem;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #968C86;
                    }
                }
                .icon-status{
                    margin-left: 0.4rem;
                    img{
                        width: 2rem;
                        height: 2rem;
                        margin: 0;
                    }
                }
            }
            .mygift-null{
                height: 5.5rem;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                img{
                    width: 4.2rem;
                    height: 2rem;
                    margin-bottom: 0.3rem;
                }
                .txt{
                    font-size: 0.52rem;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #968C86;
                }
            }
        }
    }
}
@keyframes animationWidth {
 50%,100% {transform: scale(1);}
 0%,75%,25%{transform: scale(0.95);}
 
}
</style>